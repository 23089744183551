const tableData = [
  {
    id: 1,
    title: 'Recruiting Manstatusr',
    slug: 'recuperar-senha',
  }, {
    id: 2,
    title: 'Vocês estão avisados',
    slug: 'pagamento-confirmado',
  }, {
    id: 3,
    title: 'Recruiting Manstatusr',
    slug: 'matricula-em-grupo',
  }, 
]

export { tableData };
