<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { tableData } from "./data-advancedtable";

import {
  required,
} from "vuelidate/lib/validators";

/**
 * Emails component
 */
export default {
  page: {
    title: "E-mails",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      tableData: tableData,
      title: "E-mails",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "E-mails",
          active: true,
        },
      ], 
      editor: ClassicEditor, 
      showModal: false,
      tableHighlighted: 0,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fieldAliases: {
        id: "#",
        title: "Título",
        slug: "Slug",
        actions: "Ações",
      }, 
      form: {
        title: "",
        slug: "",
        content: "",
      },   
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    }; 
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
    fields() {
      return [
        { key: "id", sortable: true, label: this.fieldAliases.id, thStyle: { width: "8%" } },
        { key: "title", sortable: true, label: this.fieldAliases.title },
        { key: "slug", sortable: true, label: this.fieldAliases.slug },
        { key: "actions", sortable: true, label: this.fieldAliases.actions, thStyle: { width: "110px" } }
      ];
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  validations: {
    form: {
      title: { required },
      slug: { required },
      content: { required },
    },
  },
  methods: {
    formSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    handleNewCad(value) {
      this.showModal = value;
    },

    deleteItem() {
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" :addText="'Adicionar Novo E-mail'" @newCad="handleNewCad" />
    <div class="row">
      <div class="col-12">
        <div class="card">        
          <div class="card-body">
            <h4 class="card-title">E-mails</h4>
            <p class="card-title-desc"></p>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Mostrar
                    <b-form-select class="form-control form-control-sm form-select form-select-sm" v-model="perPage" size="sm" :options="pageOptions"></b-form-select> Registros
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    Pesquisar: 
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Digite algo..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 datatables">
              <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(status)="obj">
                  <div class="actions-cell">    
                    <span class="badge ms-1" :class="obj.item.status == 'A' ? 'bg-success' : 'bg-warning'">
                      <h6 class="m-0">{{obj.item.status == "A" ? "Ativo" : "Inativo"}}</h6>
                    </span>                   
                  </div>
                </template>
                <!-- Coluna "actions" personalizada -->
                <template #cell(actions)="category">
                  <div class="actions-cell">                    
                    <b-button class="w-100" pill @click="deleteItem(category.item)" variant="outline-primary">
                      <i class="mdi mdi-dots-vertical"></i>
                    </b-button>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 

    <b-modal size="lg" v-model="showModal" id="modal-dialog" title="Adicionar E-mail" title-class="font-18" hide-footer>
      <form class="needs-validation" @submit.prevent="formSubmit">       
        <div class="row mb-4">
          <div class="col">
            <label class="form-label" for="title">Título <span class="required">*</span></label>
            <input
              id="title"
              v-model="form.title"
              type="text"
              class="form-control"
              placeholder="Digite o título"
              :class="{
                'is-invalid': submitted && $v.form.title.$error,
              }"
            />
            <div
              v-if="submitted && $v.form.title.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.form.title.required"
                >Por favor, digite o título.</span
              >
            </div>
          </div>
          <div class="col">
            <label class="form-label" for="title">Slug <span class="required">*</span></label>
            <input
              id="slug"
              v-model="form.slug"
              type="text"
              class="form-control"
              placeholder="Digite o slug"
              :class="{
                'is-invalid': submitted && $v.form.slug.$error,
              }"
            />
            <div
              v-if="submitted && $v.form.slug.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.form.slug.required"
                >Por favor, digite o slug.</span
              >
            </div>
          </div>
        </div>     
        <div class="row mb-4">
          <div class="col">
            <label class="form-label" for="title">Conteúdo <span class="required">*</span></label>            
            <ckeditor 
              v-model="form.content" 
              :editor="editor"      
              placeholder="Conteúdo do e-mail"      
              :class="{
                'is-invalid': submitted && $v.form.title.$error,
            }"></ckeditor>
            <div
              v-if="submitted && $v.form.content.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.form.content.required"
                >Por favor, digite o conteúdo.</span
              >
            </div>
          </div>
        </div>        
        <div class="mt-2 text-end">
            <b-button variant="light" @click="handleNewCad(false)">Fechar</b-button>
            <b-button type="submit" variant="info" class="ms-1"
              >Cadastrar</b-button
            >
          </div>
      </form>    
    </b-modal>
    <!-- end row -->
  </Layout>
</template>
